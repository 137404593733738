import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/models-ember-data",
  "date": "2015-03-26",
  "title": "MODELS & EMBER DATA",
  "author": "admin",
  "tags": ["development", "javascript", "ember.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "If you are thinking of consuming data in your Ember application, you can store them in fixtures. But what if you want to fetch data from a server?"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
App.PRODUCTS = [...];

App.ProductsRoute = Ember.Route.extend({
  model: function() {
    return App.PRODUCTS;
} });

App.ProductRouter = Ember.Route.extend({
  model: function(params) {
    return App.PRODUCTS.findBy('title', params.title);
  }
 });
`}</code></pre>
    <h2>{`Ember Models`}</h2>
    <p>{`Ember model is a class that defines the properties and behavior of the data that you present to the user. Every Route can have a Model.`}</p>
    <p>{`A Model appears under a Route because, it's the Route that tells the Controller, what Model to use.`}</p>
    <h2>{`Creating an Ember Model`}</h2>
    <p>{`We're working with products, so let's define a product class. And Modles are typically nouns.`}</p>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Product = DS.Model.extend({});
`}</code></pre>
    <p>{`Next, our Ember Model needs to know what attributes it contains.`}</p>
    <h2>{`Ember Model Attributes`}</h2>
    <p>{`We need to know what data types our Model should expect. It could be any of string, number, boolean, or a date.
We'll define all the different properties in our model class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  title: 'Flint',
  price: 99,
  description: 'Flint is...',
  isOnSale: true,
  image: 'flint.png'
}
`}</code></pre>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Product = DS.Model.extend({
        title: DS.attr('string'),
        price: DS.attr('number'),
        description: DS.attr('string'),
        isOnSale: DS.attr('boolean'),
        image: DS.attr('string')
    });
`}</code></pre>
    <h2>{`Ember Data`}</h2>
    <p>{`Ember Data makes it easy to use a Model to retrieve records from a server, cache them for performance, save updates back to the server, and create new records on the client.`}</p>
    <h2>{`Ember Data Adapters`}</h2>
    <p>{`To communicate with an HTTP server using JSON, you have to use the RESTAdapter which is the default adapter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.ApplicationAdapter = DS.RESTAdapter.extend();
`}</code></pre>
    <p>{`To load records from memory, you have to use  the FixtureAdapter, which allows you to hardcode that in fixtures for getting started.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.ApplicationAdapter = DS.FixtureAdapter.extend();
`}</code></pre>
    <h2>{`Migrating Fixtures`}</h2>
    <p>{`To convert following data to use Ember Data FixtueAdapter ..`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.PRODUCTS = [
  {
    title: 'Flint',
    price: 99,
    description: 'Flint is...',
    isOnSale: true,
    image: 'flint.png'
  },
  {
    title: 'Kindling',
    price: 249,
    description: 'Easily...',
    isOnSale: false,
    image: 'kindling.png'
} ];
`}</code></pre>
    <p>{`We need to use the FIXTURE constant within the model. Also we have to give each product a unique ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    pp. =[ {
    title: 'Flint',
    price: 99,
    description: 'Flint is...',
    isOnSale: true,
    image: 'flint.png'
  },
  { id: 2,
    title: 'Kindling',
    price: 249,
    description: 'Easily...',
    isOnSale: false,
    image: 'kindling.png'
} ];
`}</code></pre>
    <h2>{`Ember Data Store`}</h2>
    <p>{`Like many other JavaScript frameworks, Ember Dat ahs this concept of a Store. It's a central repository for records in your application, available in routes and controllers. Think of it as a cache storage of all your records. We will use the store to retrieve records and create new ones.`}</p>
    <p>{`Ember Data (by default) must use a unique identifier. We’ll use :product_id. So let's change :title to :product_id`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.resource('products', function() {
  this.resource('product', { path: '/:title' });
});
`}</code></pre>
    <p>{`Switch to using product_id as the dynamic segment`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.resource('products', function() {
  this.resource('product', { path: '/:product_id' });
});
`}</code></pre>
    <p>{`Next, we need update our Routes, in order to get our fixture data out of the store. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.ProductsRoute = Ember.Route.extend({
  model: function() {
    return App.PRODUCTS;
  }
});
`}</code></pre>
    <p>{`The following will find all products from the fixture adapter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.ProductsRoute = Ember.Route.extend({
  model: function() {
return this.store.findAll('product'); }
});
`}</code></pre>
    <p>{`To find the product with the matching ID: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.ProductRoute = Ember.Route.extend({
  model: function(params) {
    return this.store.find('product', params.product_id);
  }
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      